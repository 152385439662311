import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Drawer, Tabs, Typography } from '@nbit/arco'
import CustomModal from '@/features/agent/modal'
import { t } from '@lingui/macro'
import Icon from '@/components/icon'
import { FiatWithdrawLayout } from '@/features/assets/main/withdraw/fiat-withdraw'
import { getTradeChannel, getDefaultCurrency } from '@/apis/assets/fiat'
import { link } from '@/helper/link'
import { useC2CAdvertiseStore } from '@/store/c2c/advertise'
import { CodeGetCodeDetailListData } from '@/typings/api/assets/fiat'
import { fetchC2CPaymentReciveList, fetchC2CAreaList } from '@/apis/assets/fiat-payment'
import { getWithdrawInfo } from '@/apis/assets/fiat'
import { adCodeDictionaryEnum } from '@/constants/c2c/advertise'
import { areaListResp } from '@/typings/api/assets/fiat'
import {
  getC2cTradeRoutePath,
  getThirdPartyPaymentPageRoutePath,
  getMaiWithdrawRoutePath,
  getC2cTopupPageRoutePath,
} from '@/helper/route'
import { useMount } from 'ahooks'
import { usePersonalCenterStore } from '@/store/user/personal-center'
import { TabType } from '@/constants/assets/fiat'
import { FiatDepositLayoutModal } from './FiatDepositLayoutModal'
import styles from './index.module.css'

interface depositSidebarSettingProps {
  /** 是否显示弹窗 */
  visible: boolean
  /** 字典名字 */
  paymentDealType: CodeGetCodeDetailListData[]
  /** 字典颜色 */
  paymentColor: CodeGetCodeDetailListData[]
  /** 设置显示状态 */
  setVisible: Dispatch<SetStateAction<boolean>>
}

function DepositSidebarSetting({ visible, setVisible, paymentDealType, paymentColor }: depositSidebarSettingProps) {
  type paymentType = {
    label: string
    color: string
    value: string
    account?: string
    qrCodeAddr?: string
    IsShowRight?: boolean
    id?: string
  }

  const defaultAreaList: areaListResp = {
    countryAbbreviation: '',
    areaRiskWarn: {},
    advertRequire: '',
    payments: [],
    precision: 0,
    currencySymbol: '',
    freezeTime: 0,
    statusCd: '',
    defaultClientTypeCd: '',
    canOutTrade: '',
    sequence: 0,
    legalCurrencyId: '',
    currencyName: '',
    canPublishAdvert: false,
    paymentSupportItems: [],
  } as areaListResp

  const TabPane = Tabs.TabPane
  const [isShowModal, setIsShowModal] = useState(false)
  const [isWithdrawShowModal, setIsWithdrawShowModal] = useState(false)
  const {
    fiatCurrencyData: { currencyTypeCd },
    getFiatCurrencyData,
  } = usePersonalCenterStore()
  const [fiatCode, setFiatCode] = useState('')
  const [currentTab, setCurrentTab] = useState(TabType.charge)
  const [c2c, setC2c] = useState(false)
  const [thirdParty, setThirdPart] = useState(false)
  const [ncPlatform, setNcPlatform] = useState(false)
  const [withdraw, setWithdraw] = useState(false)
  const [min, setMin] = useState(0)
  const [max, setMax] = useState(99999999999999)
  const [cryptCode, setCryptCode] = useState('')
  const [currencyFiat, setCurrencyFiat] = useState('')
  const [fiatId, setFiatId] = useState('')
  const [paymentsAreaList, setPaymentsAreaList] = useState<areaListResp>(defaultAreaList)
  // 是否是用的字典默认值
  const [isDefault, setIsDefault] = useState(false)

  // 获取支付方式字典值
  const { adCodeDictionary, getAdCodeDictionary } = useC2CAdvertiseStore()
  const getPayTypeColorMap = () => {
    /** 通过字典值组装支付方式和颜色的映射对象 */
    const paymentType = adCodeDictionary[adCodeDictionaryEnum.Payment_Type] || []
    const paymentColor = adCodeDictionary[adCodeDictionaryEnum.Payment_Color] || []
    return Object?.keys(paymentType)?.reduce((prev, curr) => {
      const colorTypeArray = [paymentType[curr]?.codeKey, paymentColor[curr]?.codeKey]
      prev[curr] = colorTypeArray
      return prev
    }, {})
  }

  const paymentTypeLists = (): paymentType[] => {
    const payTypeColorMap = getPayTypeColorMap()
    return Object?.keys(payTypeColorMap)?.reduce((pre, cur, index) => {
      const [label, color] = payTypeColorMap[cur]
      pre.push({
        label,
        color,
        value: cur,
      })
      return pre
    }, [] as paymentType[])
  }
  const [paymentTypeFilterLists, setPaymentTypeFilterLists] = useState<paymentType[]>([])
  const [payments, setPayment] = useState<paymentType[]>(paymentTypeLists() || [])

  // 定义充值和提现的数据
  const depositData = [
    {
      key: TabType.charge,
      title: t`features_home_hero_banner_index_flh6elz9z2`,
      one: [
        {
          title: t`features_user_components_sidebar_index_ywdlxnfip_`,
          show: withdraw,
          item: [
            {
              icon: 'icon_web_revision_recharge',
              title: t`features_user_components_sidebar_index_qykymnpzp8`,
              description: t`features_user_components_sidebar_index_sedc67myrp`,
              onclick: () => {
                link(getC2cTopupPageRoutePath())
                setVisible(false)
              },
            },
          ],
        },
      ],
      two: [
        {
          title: t`features_user_components_sidebar_index_yppi2i8e94`,
          items: [
            {
              icon: 'icon_web_revision_c2c',
              title: t`features_user_components_sidebar_index_f8o8nhdjgg`,
              description: t`features_user_components_sidebar_index_tbtfq6g3af`,
              onclick: () => {
                link(getC2cTradeRoutePath())
                setVisible(false)
              },
              show: c2c,
            },
            {
              icon: 'icon_web_revision_dsf',
              title: t`modules_trade_third_party_payment_index_page_server_bkloey7_dq`,
              description: t`features_user_components_sidebar_index_lchouk70wi`,
              onclick: () => {
                link(getThirdPartyPaymentPageRoutePath())
                setVisible(false)
              },
              show: thirdParty,
            },
            {
              icon: 'icon_web_revision_chongzhi',
              title: t({
                id: 'features_user_components_sidebar_index_dmbptkonem',
                values: { 0: fiatCode },
              }),
              description: t`features_user_components_sidebar_index_kb_ytyt9tr`,
              onclick: () => {
                setIsShowModal(true)
              },
              show: ncPlatform,
            },
          ],
        },
      ],
    },
    {
      key: TabType.withdraw,
      title: t`features_user_components_sidebar_index_etltjevzl9`,
      one: [
        {
          title: t`features_user_components_sidebar_index_9sg0eelinu`,
          show: withdraw,
          item: [
            {
              icon: 'icon_web_revision_withdrawal_bit',
              title: t`features_user_components_sidebar_index_ikfca4dprl`,
              description: t`features_user_components_sidebar_index_uzrukawc4m`,
              onclick: () => {
                link(getMaiWithdrawRoutePath())
                setVisible(false)
              },
            },
          ],
        },
      ],
      two: [
        {
          title: t`features_user_components_sidebar_index_8mg3ehxf3h`,
          items: [
            {
              icon: 'icon_web_revision_c2c',
              title: t`features_user_components_sidebar_index_eyuc3mfcnz`,
              description: t`features_user_components_sidebar_index_tbtfq6g3af`,
              onclick: () => {
                link(getC2cTradeRoutePath())
                setVisible(false)
              },
              show: c2c,
            },
            {
              icon: 'icon_web_revision_dsf',
              title: t`modules_trade_third_party_payment_index_page_server_bkloey7_dq`,
              description: t`features_user_components_sidebar_index_lchouk70wi`,
              onclick: () => {
                link(getThirdPartyPaymentPageRoutePath())
                setVisible(false)
              },
              show: thirdParty,
            },
            {
              icon: 'icon_web_revision_chongzhi',
              title: t({
                id: 'features_user_components_sidebar_index_y4zixxmsmv',
                values: { 0: fiatCode },
              }),
              description: t`features_user_components_sidebar_index_kb_ytyt9tr`,
              onclick: () => {
                setIsWithdrawShowModal(true)
              },
              show: ncPlatform,
            },
          ],
        },
      ],
    },
  ]

  useEffect(() => {
    getFiatCurrencyData()
    defaultCurrency()
  }, [])

  const defaultCurrency = async () => {
    const res = await getDefaultCurrency({ fiatCode: currencyTypeCd })
    setFiatCode(res?.data)
  }

  const c2cPaymentReciveListOnLoad = async (customLegalCurrencyId?) => {
    const { isOk: isAreaOk, data: areaList } = await fetchC2CAreaList({ returnAll: true })

    const initialLegalCurrencyId = areaList?.find(val => val?.currencyName === fiatCode)?.legalCurrencyId

    const legalCurrencyId = customLegalCurrencyId || initialLegalCurrencyId

    if (legalCurrencyId) {
      const { isOk: isPaymentOk, data: paymentList } = await fetchC2CPaymentReciveList(
        legalCurrencyId ? { legalCurrencyId } : {}
      )
      const recivePaymentList = paymentList?.recivePaymentList

      const updatedRecivePaymentList = recivePaymentList?.map(item => {
        const paymentDealTypeInfo = paymentDealType.find(dealType => dealType.codeVal === item.paymentTypeCd)
        const paymentColorInfo = paymentColor.find(color => color.codeVal === item.paymentTypeCd)
        const label = paymentDealTypeInfo ? paymentDealTypeInfo.codeKey : item.paymentTypeCd
        const color = paymentColorInfo ? paymentColorInfo.codeKey : ''

        return {
          label,
          color,
          value: item?.paymentTypeCd,
          id: item?.id,
        }
      })
      if (updatedRecivePaymentList && updatedRecivePaymentList.length !== 0) {
        setPaymentTypeFilterLists(updatedRecivePaymentList)
        setIsDefault(false)
      } else {
        setPaymentTypeFilterLists(paymentTypeLists())
        setIsDefault(true)
      }
      const paymentsAreaList = areaList?.find(val => val.legalCurrencyId === legalCurrencyId)
      if (paymentsAreaList) {
        setPaymentsAreaList(paymentsAreaList)
      }
      const paymentsData = paymentsAreaList?.payments
      const updatedPaymentsList = paymentsData?.map(item => {
        const paymentDealTypeInfo = paymentDealType.find(dealType => dealType.codeVal === item)
        const paymentColorInfo = paymentColor.find(color => color.codeVal === item)
        let accountInfo
        if (recivePaymentList && recivePaymentList?.length > 0) {
          accountInfo = recivePaymentList?.filter(account => account.paymentTypeCd === item)
        }
        let showRight
        if (accountInfo && accountInfo.length > 1) {
          showRight = true
        } else {
          showRight = false
        }
        return {
          label: paymentDealTypeInfo ? paymentDealTypeInfo.codeKey : item,
          color: paymentColorInfo ? paymentColorInfo.codeKey : '',
          value: item,
          account: accountInfo && accountInfo[0]?.account ? accountInfo[0]?.account : '',
          qrCodeAddr: accountInfo && accountInfo[0]?.qrCodeAddr ? accountInfo[0]?.qrCodeAddr : '',
          IsShowRight: showRight,
          id: accountInfo && accountInfo[0]?.id ? accountInfo[0]?.id : '',
          accountInfo,
        }
      })
      if (updatedPaymentsList) {
        setPayment(updatedPaymentsList)
      }
    }
  }

  const changeAccount = (val, paymentMeth) => {
    const newData = payments.map(item => {
      if (item.value === paymentMeth) {
        return { ...item, account: val }
      }
      return item
    })
    setPayment(newData)
  }

  const getShowTradeChannel = async type => {
    const res = await getTradeChannel(type)
    if (!res.data) return
    const { c2c, thirdParty, ncPlatform, withdraw } = res.data
    setC2c(c2c)
    setThirdPart(thirdParty)
    setNcPlatform(ncPlatform)
    setWithdraw(withdraw)
  }

  useEffect(() => {
    if (currentTab === TabType.charge) {
      // 充值
      getShowTradeChannel({ type: TabType.charge })
    } else if (currentTab === TabType.withdraw) {
      // 提现
      fetchData(fiatCode)
      getShowTradeChannel({ type: TabType.withdraw })
    }
  }, [currentTab])

  useMount(() => {
    getAdCodeDictionary()
  })

  const fetchData = async code => {
    const res = await getWithdrawInfo({ fiatCode: code })
    if (!res.data) return
    const { min, max, cryptCode, fiatCode, fiatId } = res.data
    setFiatId(fiatId)
    setCurrencyFiat(fiatCode)
    setMin(min || 0)
    setMax(max || 99999999999999)
    setCryptCode(cryptCode)
  }

  const changeLegalCurrencyId = () => {
    c2cPaymentReciveListOnLoad(fiatId)
  }

  const changeCharge = val => {
    fetchData(val)
  }

  return (
    <div>
      <Drawer
        width={400}
        title={t`features_user_components_sidebar_index__l9rq30wke`}
        visible={visible}
        footer={null}
        className={`deposit-setting-drawer-style ${styles.scoped}`}
        onOk={() => {
          setVisible(false)
        }}
        onCancel={() => {
          setVisible(false)
        }}
      >
        <Tabs
          defaultActiveTab={currentTab}
          onClickTab={key => {
            setCurrentTab(key as TabType)
          }}
        >
          {depositData.map((tab, index) => (
            <TabPane key={tab.key} title={tab.title}>
              <Typography.Paragraph>
                {tab?.one?.map(
                  (item, itemIndex) =>
                    item.show && (
                      <div key={String(itemIndex)} className="mb-6">
                        <p className="deposit-title">{item.title}</p>
                        {item?.item?.map((i, index) => (
                          <div
                            className="deposit-box"
                            key={index}
                            onClick={() => {
                              if (!i.onclick) return
                              i?.onclick()
                            }}
                          >
                            <Icon name={i.icon} className="icon-assets" />
                            <div className="deposit-box-middle">
                              <p className="title">{i.title}</p>
                              <p>{i.description}</p>
                            </div>
                            <Icon name="next_arrow" className={'arrow-icon'} />
                          </div>
                        ))}
                      </div>
                    )
                )}
                {tab?.two?.map((item, itemIndex) => (
                  <div key={String(itemIndex)} className="mb-6">
                    {(c2c || thirdParty || ncPlatform) && <p className="deposit-title">{item.title}</p>}
                    {item?.items?.map(
                      (i, index) =>
                        i.show && (
                          <div
                            className="deposit-box deposit-pt"
                            key={index}
                            onClick={() => {
                              if (!i.onclick) return
                              i?.onclick()
                            }}
                          >
                            <Icon name={i.icon} className="icon-assets" />
                            <div className="deposit-box-middle">
                              <p className="title">{i.title}</p>
                              <p>{i.description}</p>
                            </div>
                            <Icon name="next_arrow" className={'arrow-icon'} />
                          </div>
                        )
                    )}
                  </div>
                ))}
              </Typography.Paragraph>
            </TabPane>
          ))}
        </Tabs>
      </Drawer>

      {isShowModal && (
        <FiatDepositLayoutModal
          isShowModal={isShowModal}
          fiatCode={fiatCode}
          paymentDealType={paymentDealType}
          setClose={() => {
            setIsShowModal(false)
          }}
        />
      )}

      {isWithdrawShowModal && (
        <CustomModal style={{ width: 600 }} visible={isWithdrawShowModal}>
          <FiatWithdrawLayout
            paymentTypeFilterLists={paymentTypeFilterLists}
            payments={payments}
            firstFiatCode={fiatCode}
            currencyFiat={currencyFiat}
            isDefault={isDefault}
            max={max.toString()}
            min={min.toString()}
            paymentsAreaList={paymentsAreaList}
            onclick={() => {
              setIsWithdrawShowModal(false)
              setVisible(false)
            }}
            changeLegalCurrencyId={changeLegalCurrencyId}
            changeCharge={changeCharge}
            changeAccount={changeAccount}
          />
        </CustomModal>
      )}
    </div>
  )
}

export { DepositSidebarSetting }
